var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-image-web-component table-component" },
    [
      _c(
        "div",
        { staticClass: "page-body page-body-margin-left-16" },
        [
          _c(
            "a-card",
            { attrs: { title: "Web端图片配置" } },
            [
              _c("jtl-form", { ref: _vm.JTL.CONSTANT.DEFAULT_FORM_NAME }),
              _c(
                "div",
                { staticClass: "save-button" },
                [
                  _c(
                    "jtl-button",
                    {
                      attrs: {
                        type: "primary",
                        "click-prop": _vm.saveClick.bind(this)
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }