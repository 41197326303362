













import { Component, Ref } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { ProjectEntityModel } from '@/entity-model/project-entity';
import ProjectService from '@/service/project';
import { UserStoreModule } from '@/store/modules/user';
import FormComponent from '@/mixins/form-component';
import { ViewModeType } from '@/model/enum';
import { FormControlType } from '@/model/form-control';

@Component
export default class ConfigImageWebComponent extends BaseComponent {
    /**
     * 表单组件
     */
    @Ref(JTL.CONSTANT.DEFAULT_FORM_NAME)
    jtlForm: FormComponent<any>;

    mounted() {
        if (!_.get(UserStoreModule.CurrentProject, 'id')) {
            this.showMessageWarning('没有选择项目');
            return;
        }
        const loginBgValue = _.get(UserStoreModule.CurrentProject, 'loginBg');
        const logoValue = _.get(UserStoreModule.CurrentProject, 'logo');
        const controlList = [
            {
                key: 'loginBg',
                label: '登录页面',
                value: loginBgValue ? [{ url: loginBgValue, type: 'image' }] : [],
                type: FormControlType.UPLOAD,
                listType: 'picture-card',
                showUploadList: true,
                maxFileNumber: 1,
                message: '建议上传尺寸1920 * 1080',
                noMessageTooltip: true,
                layout: { labelCol: 5, wrapperCol: 12, width: '100% ' },
                accept: '.jpg,.png,.gif'
            },
            {
                key: 'logo',
                label: '项目LOGO',
                value: logoValue ? [{ url: logoValue, type: 'image' }] : [],
                type: FormControlType.UPLOAD,
                listType: 'picture-card',
                showUploadList: true,
                maxFileNumber: 1,
                message: '建议上传尺寸48 * 48',
                noMessageTooltip: true,
                layout: { labelCol: 5, wrapperCol: 12, width: '100% ' },
                accept: '.jpg,.png,.gif'
            }
        ];
        this.jtlForm.initFormByControls(controlList, ViewModeType.UPDATE);
    }
    saveClick() {
        const projectId = _.get(UserStoreModule.CurrentProject, 'id');
        const webInfo = {
            loginBg: _.get(this.jtlForm.formModel.loginBg, '[0].url'),
            logo: _.get(this.jtlForm.formModel.logo, '[0].url')
        };
        return ProjectService.saveWebInfo(projectId, webInfo).then(res => {
            const projectEntity = UserStoreModule.CurrentProject;
            projectEntity.loginBg = webInfo.loginBg;
            projectEntity.logo = webInfo.logo;
            UserStoreModule.SET_CURRENT_PROJECT(projectEntity);
            this.showMessage('保存成功！', 'success');
        }).catch(err => {
            throw err;
        });
    }
}

